@use "@globals" as g;
.accidentFlowImg {
  &-wrapper {
    margin-top: g.vw(30);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include g.media('lg') {
      margin-top: 60px;
    }
  }
  &-box {
    width: 48%;
    margin-bottom: g.vw(18);
    @include g.media('md') {
      max-width: 360px;
      width: 32%;
      margin-bottom: g.vw(15);
    }
    @include g.media('lg') {
      max-width: 360px;
      margin-bottom: 35px;
    }
    &__img {
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 0.25em;
    }
    &__title {
      display: flex;
      align-items: center;
      font-size: g.vw(10);
      font-weight: 700;
      @include g.media('md') {
        font-size: g.vw(6.5);
      }
      @include g.media('lg') {
        font-size: 20px;
      }
      span {
        color: var(--color-waterBlue);
        font-family: "Montserrat", sans-serif;
        font-size: g.vw(16);
        font-weight: 700;
        margin-right: 0.5em;
        @include g.media('md') {
          font-size: g.vw(10);
        }
        @include g.media('lg') {
          font-size: 36px;
        }
      }
    }
  }
}
.accidentFlowList {
  &-wrapper {
    background: var(--color-gray);
    border-radius: 20px;
    margin-bottom: g.vw(40);
    padding: g.vw(25) g.vw(20);
    @include g.media('md') {
      margin-bottom: g.vw(20);
      padding: g.vw(15) g.vw(25);
    }
    @include g.media('lg') {
      width: 880px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: g.vw(20);
      padding: 50px 70px;
    }
    li {
      font-weight: 500;
      font-size: g.vw(9);
      @include g.media('md') {
        font-size: g.vw(8);
      }
      @include g.media('lg') {
        font-size: 20px;
      }
    }
  }
}
