@use "@globals" as g;

.shopSection01 {

  &__link {
    margin-top: 35px;
    border-left: 1px solid var(--color-black);
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    transition: all .5s ease-out;
    
    @include g.media('md') {
      margin-top: 80px;
    }

    & li {

      &:not(:first-child) {
        border-left: 1px solid var(--color-black);
      }
      &:last-child {
        border-right: 1px solid var(--color-black);
      }

      & a {
        color: var(--color-black);
        display: block;
        padding: 0 15px;

        &:hover {
          opacity: .6;
        }

        @include g.media('md') {
          padding: 0 20px;
        }
      }
    }
  }
}