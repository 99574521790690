@use '@globals' as g;

.h1,.h2,.h3,.h4,.h5,.h6,.div {
  font-weight: 700;
}

.h1 {
  font-size: 80px;
  text-align: center;

  @include g.media('lg') {
    font-size: 80px;
    text-align: center;
  }
}
.h2 {
  font-size: 26px;
  font-weight: 700;

  @include g.media('lg') {
    font-size: 26px;
    font-weight: 700;
    &__sub {
      color: var(--color-blue);
      font-family: "Montserrat", sans-serif;
      font-size: 11px;
      font-weight: 700;
      margin-bottom: 1em;
      &::before {
        content: '●　';
        font-size: 9px;
      }
    }
    &__wrapper {
      margin-bottom: 40px;
    }
  }



  &__sub {
    color: var(--color-blue);
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 1em;
    &::before {
      content: '●　';
      font-size: 9px;
    }
  }

  &__wrapper {
    margin-bottom: 40px;
  }
}
.h3 {
  border-bottom: 1px solid #dee3e7;
  font-size: g.auto_clamp(17, 375, 24, 1240);
  font-weight: 500;
  padding-bottom: g.vw(15);
  position: relative;
  &::after {
    background: #717be4;
    content: '';
    display: block;
    width: 120px;
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 0;
  }

  @include g.media('lg') {
    border-bottom: 1px solid #dee3e7;
    // font-size: 24px;
    padding-bottom: 30px;
    position: relative;
    &::after {
      background: #717be4;
      content: '';
      display: block;
      width: 260px;
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }
}
.h4 {
  border-left: 10px solid var(--color-blue);
  font-size: 1.3em;
  padding-left: 1em;
  line-height: 1.4em;
  margin-bottom: 1.5em;
}
.h5 {
  font-size: 18px;
}
.h6 {
  font-size: 16px;
}
.div {
  font-size: g.$default_fz;
}
