@use "@globals" as g;

.shop-detail {
  &__welcome {
    padding: 30px 0;
  }

  &__cover {
    @include g.media('md') {
      
    }
    & h3 {
      @include g.media('md') {
        display: none;
      }
    }
  }

  &__basic-cover {
    margin-bottom: 30px;
    @include g.media('md') {
      display: none;
    }
  }

  &__name-area {
    margin-bottom: 30px;

    @include g.media('md') {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }
  &__name-area-title {
    font-size: 2rem;
    font-weight: 700;

    @include g.media('md') {
      font-size: 2.8rem;
    }
  }
  &__name-area-sns {
    display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    margin-top: -30px;

    @include g.media('md') {
      align-items: center;
      justify-content: flex-start;
      margin-top: unset;
    }
  }

  &__related {
    font-size: 1.8rem;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: .5rem;

    & li {
      font-size: 1.4rem;
      letter-spacing: .5;
      margin-right: 1.5em;
      padding: 0 1rem;
    }
    & li[data-icon="各種保険"] {
      background: url('/svg/icon_hoken_black.svg') no-repeat left top 50%;
      padding-left: 2.2em;
    }
    & li[data-icon="カードOK"] {
      background: url('/svg/icon_card_black.svg') no-repeat left top 50%;
      padding-left: 2.2em;
    }
    & li[data-icon="キッズルーム完備"] {
      background: url('/svg/icon_kids_black.svg') no-repeat left top 50%;
      padding-left: 2.1em;
    }
    & li[data-icon="ベビーベッド完備"] {
      background: url('/svg/icon_babybed_black.svg') no-repeat left top 50%;
      padding-left: 2em;
    }
    & li[data-icon="大型駐車場"] {
      background: url('/svg/icon_parking_black.svg') no-repeat left top 50%;
      padding-left: 1.5em;
    }
  }

  &__explain {
    display: flex;
      flex-direction: column;
    
    @include g.media('lg') {
      flex-direction: row;
      margin-bottom: 60px;
    }
  }

  &__explain-image-wrap {
    display: none;
    text-align: center;
    width: 100%;
    @include g.media('lg') {
      display: block;
      width: 50%;
    }
  }

  &__explain-text {
    width: 100%;
    @include g.media('lg') {
      padding-left: 70px;
      width: 50%;
    }
  }

  &__access {
    display: flex;
      flex-wrap: wrap;

    & dt, & dd {
      font-size: 1.5rem;
      margin-bottom: 25px;
      @include g.media('md') {
        font-size: 1.8rem;
      }
    }
    
    & dt {
      font-weight: 400;
      width: 100px;
    }
    & dd {
      width: calc(100% - 100px);
    }
  }
  
  &__address {
  }

  &__map-pin {
    color: var(--color-black);
    padding-left: 30px;
    position: relative;

    @include g.media('md') {
      &:hover {
        opacity: .6;
      }
    }

    &::before {
      content: url('/images/icons/icon_map_pin.svg');
      width: 15px;
      height: 22px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__schedule {
    width: 100%;
    margin-bottom: 60px;
  }

  &__googlemap {
    width: 100%;
    margin-bottom: 60px;

    & iframe {
      width: 100%;
      height: 320px;

      @include g.media('md') {
        height: 420px;
      }
    }
  }

  &__access-guide {
    padding-bottom: 100px;
  }

  &__access-way {
    display: flex;
      flex-direction: column;
    margin-top: 45px;

    @include g.media('md') {
      flex-direction: row;
      justify-content: flex-start;
      gap: 70px;
    }
  }

  &__access-label {
    border-radius: 30px;
    border: 1px solid var(--color-blue);
    color: var(--color-blue);
    display: flex;
      justify-content: center;
      align-items: center;
    margin-bottom: 25px;
    text-align: center; 
    width: 220px;
    height: 45px;

    @include g.media('md') {
      margin-bottom: 40px;
    }
  }

  &__ekiten-link {
    display: block;
    margin: 60px auto 0;
    text-align: center;
  }

}