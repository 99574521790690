@use "@globals" as g;

.shop-list-wrapper {
  margin: 45px 0;
  
  @include g.media('md') {
    margin: 60px 0 170px;
  }
  &__inner {
    @include g.media('lg') {
      margin-top: -120px;
      padding-top: 120px;
    }
  }
}