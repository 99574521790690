@use "@globals" as g;

.l_section {
  position: relative;
  margin-bottom: 250px;
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    margin-bottom: g.vw(100);
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: g.vw(100);
  }
  @media screen and (max-width: 767px) {
    margin-bottom: g.vw(100);
  }
  &::after {
    content: 'GALLERY';
    color: #eff5e1;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -200px;
    @media screen and (min-width: 768px) {
      font-size: 200px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1399px) {
      font-size: g.vw(50);
      bottom: g.vw(-55);
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: g.vw(70);
      bottom: g.vw(-80);
    }
    @media screen and (max-width: 767px) {
      font-size: g.vw(70);
      bottom: g.vw(-70);
    }
  }
}