@use "@globals" as g;
.aboutSection01 {
  padding-bottom: 60px;
  margin-top: 170px;
  position: relative;

  &::before {
    background: url('/images/AboutSection01_bg_01.png') no-repeat;
    content: '';
    display: block;
    width: 1024px;
    height: 400px;
    position: absolute;
    top: -300px;
    right: 0;
    z-index: -1;
  }
  &::after {
    background: url('/images/AboutSection01_bg_02.png') no-repeat;
    content: '';
    display: block;
    width: 565px;
    height: 339px;
    position: absolute;
    bottom: -100px;
    left: 0;
    z-index: -1;
  }

  @include g.media('md') {
    padding-bottom: 140px;
  }
  
  &__inner {
    padding-right: g.vw(24);
    padding-left: g.vw(24);
    position: relative;

    @include g.media('md') {
      margin-right: auto;
      margin-left: auto;
      padding-left: 0;
      padding-right: 0;
      max-width: 1160px;
      width: 95%;
      z-index: 10;
    }
    @include g.media('xl') {
      width: 1160px;
    }
  }

  &__contents {
    display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;

    @include g.media('md') {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__img {
    margin-bottom: g.auto_clamp(25,721,25,1024);
    @include g.media('md') {
      width: g.auto_clamp(270, 721, 460, 1024);
      transform: translateY(-110px);
    }
    @include g.media('lg') {
      width: g.auto_clamp(460, 1025, 590, 1240);
      transform: translateY(-50px)
    }
  }

  &__text {
    width: 100%;

    @include g.media('md') {
      width: g.auto_clamp(400, 721, 500, 1024);
    }

    @include g.media('lg') {
      width: 500px;
    }
  }

  @media screen and (min-width: 1400px) {
    padding-bottom: 140px;
    position: relative;

    &::before {
      background: url('/images/AboutSection01_bg_01.png') no-repeat;
      content: '';
      display: block;
      width: 1069px;
      height: 448px;
      position: absolute;
      top: -300px;
      right: 0;
    }
    &::after {
      background: url('/images/AboutSection01_bg_02.png') no-repeat;
      content: '';
      display: block;
      width: 565px;
      height: 339px;
      position: absolute;
      bottom: -100px;
      left: 0;
    }

    &__contents {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__text {
      width: 500px;
      &__wrapper {
        p {
          font-size: 1.5rem;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}