@use "@globals" as g;

.treatment-link-card {
  width: 48%;
  margin-bottom: 50px;
  &__text {
    padding-top: 20px;
    &__title {
      margin-bottom: 30px;
      a {
        color: #189bef;
        font-size: g.auto_clamp(17, 375, 24, 1440);
        font-weight: 700;
      }
    }
    &__p {
      font-size: g.vw(14);
    }
  }

  @include g.media('sm') {
    width: calc( (100% - 40px) / 2);
    &__text {
      padding-top: 20px;
      &__title {
        margin-bottom: 30px;
        a {
          color: #189bef;
          // font-size: 24px;
          font-weight: 700;
        }
      }
      &__p {
        font-size: 16px;
      }
    }
  }
  // @include g.media('lg') {
  //   width: 360px!important;
  // }
  @include g.media('md') {
    width: calc((100% - 80px) / 3);
  }
  &__img {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 16px -6px rgba(0,0,0,0.6);
    img {
      transition: all .5s ease-out;
    }
    a:hover {
      img {
        transform: scale(1.05);
      }
    }
    &::after {
      background: url('/svg/icon_arrow_to_right_dark_blue.svg') no-repeat;
      width: g.vw(18);
      height: g.vw(4);
      content: '';
      display: block;
      position: absolute;
      bottom: g.vw(12);
      right: g.vw(12);
    }
    @include g.media('lg') {
      &::after {
        width: 45px;
        height: 11px;
        bottom: 30px;
        right: 30px;
      }
    }
  }
}