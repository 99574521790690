@use "@globals" as g;
.imageContainer  {
  position: relative;
  width: 100%;
  height: 100%;
  @include g.media('md') {
    display: none;
  }
}
.scrollSlider {
  position: relative;
  &__image {
    position: relative;
  }
  &__bgText {
    color: #eff5e1;
    font-size: 200px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    margin: auto;
    position: absolute;
    bottom: -1em;
    left: 0;
    right: 0;
  }
  
}