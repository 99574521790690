@use "@globals" as g;
.accidentRelief {
  &-inner {
    padding-top: g.vw(20);
    padding-bottom: g.vw(20);
    @include g.media('md') {
      padding-top: g.vw(10);
      padding-bottom: g.vw(10);
    }
    @include g.media('lg') {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  &__title {
    color: var(--color-waterBlue);
    font-weight: 700;
    font-size: g.vw(15);
    margin-bottom: g.vw(15);
    @include g.media('md') {
      font-size: g.vw(10);
      margin-bottom: g.vw(10);
    }
    @include g.media('lg') {
      font-size: 22px;
      margin-bottom: 30px;
    }
  }
  &__img {
    text-align: center;
  }
  &__grayBox {
    background: var(--color-gray);
    border-radius: 20px;
    margin: g.vw(20) 0 g.vw(13);
    padding: g.vw(20);
    @include g.media('md') {
      margin: g.vw(12) 0 g.vw(8);
      padding: g.vw(10);
    }
    @include g.media('lg') {
      margin: 40px 0 20px;
      padding: 35px;
    }
    p {
      color: var(--color-waterBlue);
      font-weight: 700;
      font-size: g.vw(13);
      @include g.media('md') {
        text-align: center;
        font-size: g.vw(7);
      }
      @include g.media('lg') {
        text-align: center;
        font-size: 22px;
      }  
    }
  }
}
