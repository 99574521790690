@use "@globals" as g;

.title-wrapper {
    background: url('/images/pageTitleBg.png') no-repeat top center / cover;
    padding: g.auto_clamp(28, 375, 75, 1024) g.auto_clamp(30, 375, 115, 1024);

  @include g.media('lg') {
    background: url('/images/pageTitleBg.png') no-repeat top center / cover;
    padding: 75px 0 115px;
  }
}