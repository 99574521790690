@use "@globals" as g;

.treatment-menu-list {
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;

  @include g.media('md') {
    justify-content: flex-start;
    gap: 30px 20px;
    margin-top: 60px;
  }
  @include g.media('lg') {
    gap: 60px 40px;
  }
}