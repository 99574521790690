@use "@globals" as g;

.shopList {
  &__wrapper {
    background: var(--color-white);
  }
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 1400px) {
    align-items: center;
    justify-content: center;
    gap: 2%;
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    align-items: center;
    justify-content: center;
    gap: 2%;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 767px) {
    justify-content: space-between;
  }
  li {
    margin-bottom: 2em;
    @media screen and (min-width: 1400px) {
      width: 30%;
    }
    @media screen and (min-width: 1025px) and (max-width: 1399px) {
      width: 30%;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 45%;
    }
    @media screen and (max-width: 767px) {
      width: 45%;
    }
  }
  a {
    transition: all .2s ease-out;
    &:hover {
      opacity: 0.6;
    }
  }
  &__name {
    color: var(--color-black);
    font-size: 14px;
    text-align: center;
  }
}