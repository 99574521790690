@use "@globals" as g;

.staff-item {
  &__img {
    position: relative;
    margin-bottom: 65px;
  }

  &__name {
    color: var(--color-blue);
    font-family: "Montserrat", sans-serif;
    font-size: g.auto_clamp(20, 375, 48, 1440);
    font-weight: 700;
    line-height: 1.3;
    position: absolute;
    bottom: -32px;
    left: 32px;
  }

  &__text {
    padding: 0 g.auto_clamp(15, 375, 30, 1440) 0 g.auto_clamp(25, 375, 50, 1440);

    &__name {
      font-size: g.auto_clamp(18, 375, 24, 1440);
      font-weight: 700;
      margin-bottom: 35px;
    }

    &__info {
      display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: column;
        gap: g.auto_clamp(5, 375, 10, 1440) g.auto_clamp(10, 375, 15, 1440);

        @include g.media('lg') {
          flex-direction: row
        }

      & dt {
        border: 1px solid var(--color-blue);
        border-radius: 20px;
        color: var(--color-blue);
        font-size: g.auto_clamp(15, 375, 16, 1440);
        font-weight: 700;
        padding: 0 g.auto_clamp(10, 375, 20, 1440);
        text-align: center;
        text-align-last: justify;
        width: g.auto_clamp(70, 375, 105, 1440);
      }
      & dd {
        font-size: g.auto_clamp(15, 375, 16, 1440);
        width: 100%;
        @include g.media('lg') {
          width: calc((100% - ( g.auto_clamp(70, 375, 105, 1440) + g.auto_clamp(10, 375, 20, 1440) )));
        }
      }
    }
  }
}