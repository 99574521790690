@use "@globals" as g;

.shopInfoSection03 {
  &__link {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-direction: column;

    @include g.media('md') {
      flex-direction: row;
      gap: 30px;
    }

    &__box {
      background: var(--color-white);
      border-radius: 20px;
      width: 100%;

      @include g.media('md') {
        width: calc((100% - 30px) / 2);
        max-width: 564px;
      }

      @include g.media('xl') {
        width: 564px;
      }
      a {
        display: block;
        padding: 40px 40px 25px 40px;
        transition: all .2s ease-out;
        &:hover {
          opacity: 0.6;
        }
        &::after {
          background: var(--color-blue) url('/svg/icon_arrow.svg') no-repeat center 50%;
          border-radius: 20px;
          content: '';
          display: block;
          width: 80px;
          height: 40px;
          margin-top: g.vw(52);
          margin-left: auto;
          @include g.media('lg') {
            margin-top: 96px;
          }
        }
      }
      & > a {
        border-radius: 20px;
        overflow: hidden;
      }
      &:nth-of-type(1) a {
        background: url('/images/shopInfoSection03_img_01.png') no-repeat top center;
        background-size: cover;
      }
      &:nth-of-type(2) a {
        background: url('/images/shopInfoSection03_img_02.png') no-repeat top center;
        background-size: cover;
      }
      &__en {
        color: var(--color-green);
        font-size: 11px;
        font-weight: 600;
        &::before {
          content: '●　';
        }
      }
      &__title {
        font-size: 26px;
        font-weight: 700;
        margin: 0 0 10px;
      }
      &__text {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}