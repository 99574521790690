@use "@globals" as g;

.l_section--m {
  // padding-bottom: 30px;
  margin-top: 60px;
  @include g.media('md') {
    margin-top: 3em;
    // padding-bottom: 4vw;
  }
  @include g.media('xl') {
    // padding-bottom: 140px;
  }
}