@use "@globals" as g;
.voice {
  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 3em;
    padding-bottom: 1em;
  }
  &__eyecatch {
    border-radius: 20px;
    overflow: hidden;
    margin-right: 2em;
    margin-left: 2em;
  }
  &__box {
    margin-bottom: 5em;
  }
  &__detail__title {
    color: var(--color-blue);
    font-size: 1.6em!important;
  }
}