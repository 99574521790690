@use '@globals' as g;
.link_button {
  display: inline-block;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: block;
  }
  @media screen and (max-width: 767px) {
    display: block;
  }
  & a {
    background: var(--color-blue) url('/svg/icon_arrow.svg') no-repeat right 35px top 50% / 35px 10px;
    border-radius: 80px;
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    transition: all .2s ease-out;
    &:hover {
      opacity: 0.6;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.6rem;
      padding: g.vw(5) g.vw(28) g.vw(5) g.vw(18);
    }
    @media screen and (min-width: 1025px) and (max-width: 1399px) {
      font-size: g.vw(5.5);
      padding: g.vw(5) g.vw(30) g.vw(5) g.vw(15);
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      min-width: 250px;
    }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      background-size: g.vw(15);
      padding: g.vw(8) g.vw(20);
    }
    @media screen and (max-width: 767px) {
      font-size: g.vw(14);
      background-size: g.vw(20);
      padding: g.vw(15) g.vw(30);
    }
  }
};