@use "@globals" as g;

.shop-sec {
  background: var(--color-white);
  padding: 30px 0 60px;
  
  @include g.media('md') {
    padding: 60px 0;
  }

  &-inner {
    padding-right: 20px;
    padding-left: 20px;
    margin: auto;

    @include g.media('md') {
      width: 95%;
      margin-right: auto;
      margin-left: auto;
      padding-right: 0;
      padding-left: 0;
    }

    @media screen and (min-width: 1400px) {
      width: 100%;
      max-width: 1160px;
    }
  }

  &__title-area {

  }

  &__posts {
    margin-bottom: 35px;
    @include g.media('md') {
      margin-bottom: 65px;
    }
  }

  &__link-wrapper {
    text-align: center;
  }

}