@use "@globals" as g;

.imageContainer  {
  display: none;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  img {
    border-radius: 20px;
    box-shadow: 0px 0px 12px -6px rgba(0,0,0,0.6);
  }
  @include g.media('md') {
    display: block;
    margin-bottom: 10px;
  }
}

.scrollSlider {
  position: relative;
  &__image {
    position: relative;
  }
  &__bgText {
    color: #eff5e1;
    font-size: 200px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    margin: auto;
    position: absolute;
    bottom: -1em;
    left: 0;
    right: 0;
  }
  
}