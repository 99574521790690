@use '../../../../scss/globals/' as g;

.treatment-link-button {
  display: block;
  background: url('/svg/icon_arrow_to_right_light_blue.svg') no-repeat right 38px top 50% / 35px 10px;
  
  & a {
    border: 1px solid var(--color-waterBlue);
    border-radius: 50px;
    color: var(--color-waterBlue);
    display: block;
    font-weight: 700;
    padding: 6px 30px;
    transition: all .2s ease-out;
    &:hover {
      opacity: 0.6;
    }

    @include g.media('md') {
      padding: 17px 40px;
    }
  }
};