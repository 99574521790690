@use "@globals" as g;
.title-wrapper {
  // padding: 30px 15px 15px;
  // padding: 0 15px 15px;
  @include g.media('md') {
    // padding: 0 60px 30px;
  }
}
.archive-body {
  display: flex;
  flex-direction: column-reverse;
  @include g.media('md') {
    border-top: 1px solid #dbdbdb;  
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: min(100%,1160px);
  }

  &__menu {
    width: 100%;
    padding: 20px 10px 0;
    @include g.media('md') {
      border-right: 1px solid #dbdbdb;
      width: 20%;
      padding: 40px 20px 0;
    }
  }

  &__contents {
    padding: 20px;
    width: 100%;
    @include g.media('md') {
      width: 80%;
      padding: 40px;
    }
  }
}

