@use "@globals" as g;

.contactSection01 {
  margin-bottom: 60px;
  &__title {
    font-size: g.auto_clamp(20, 375, 30, 1440);
    font-weight: 700;
    text-align: center;
    margin-bottom: g.auto_clamp(25, 375, 30, 1440);
  }
  &__text {
    font-size: 1.6rem;
    margin-bottom: g.auto_clamp(60, 375, 80, 1440);
    @include g.media('sm') {
      text-align: center;
    }
  }
  &__tel {
    font-size: min(g.vw(20),24px);
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.5em;
    a {
      color: var(--color-black);
    }
  }
  &__reserved {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
    margin-bottom: 5em;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__iframeWrapper {
    text-align: center;
    iframe {
      max-width: 100%;
    }
  }
}