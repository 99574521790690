@use '../../../scss/globals/' as g;

.hero {
  display: block;
  @media screen and (min-width: 768px) {
    background: url('/images/bg_01.png') no-repeat 0 0 / 100% 100%;
    padding-bottom: 300px;
    &__image {
      width: 1440px;
      min-height: 1050px;
      margin-right: auto;
      margin-left: auto;
      padding-top: 45px;
      text-align: right;
      position: relative;
      & img {
        width: initial;
      }
    }
  
    &__catch {
      position: absolute;
      top: 485px;
      left: 90px;
      text-align: left;
      z-index: 10;
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    padding-bottom: g.vw(80);
    &__image {
      width: calc(100% - g.vw(20));
      min-height: g.vw(350);
      margin-right: 0;
    }
    &__catch {
      top: g.vw(150);
      left: g.vw(10);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-bottom: g.vw(100);
    &__image {
      width: 100%;
      min-height: g.vw(425);
      padding-left: g.vw(20);
    }
    &__catch {
      top: g.vw(150);
      left: g.vw(30);
    }
  }
  @media screen and (max-width: 767px) {
    background: url('/images/bg_01_sp.png') no-repeat center bottom / 100%;
    padding: g.vw(18) 0 g.auto_clamp(5, 375, 405, 720) g.vw(18);
    &__image {
      min-height: 840px
    }
    &__catch {
      margin-top: g.vw(-90);
      padding-right: g.vw(18);
      position: relative;
      z-index: 10;
    }
  }
}