@use "@globals" as g;

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
  flex-direction: column;
  height: 65.39px;
  @include g.media('sm') {
    height: 27.59px;
  }
}
.embla__slide {
  min-width: 0;
  color: white;
  padding: 5px 0;
}

.simple_listTop {
  background: #2D49EB;

  &__title {
    color: var(--color-green);
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 10px;
    @include g.media('md') {  
      margin-bottom: 0;
    }
  }
  @include g.media('md') {
    border-radius: 40px;
    display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    margin-top: 240px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 75px;
    width: 1247px;

    &__title {
      font-size: 18px;
    }

    &__item {
      width: 810px;
      a,span {
        color: var(--color-base);
      }
    }

    &__link {
      background: url('/svg/icon_arrow.svg') no-repeat right 50%;
      font-family: "Montserrat", sans-serif;
      font-size: 1.2rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding-right: 50px;
      transition: all .2s ease-out;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    width: calc(100% - g.vw(12));
    border-radius: 50px;
    margin-top: g.auto_clamp(240, 1025, 390, 1339);
    margin-right: 0;
    margin-left: 0;
    padding: 25px;
    &__item {
      width: auto;
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    border-radius: 20px;
    margin-top: g.vw(120);
    margin-left: g.vw(-10);
    padding: g.vw(10);
    &__item {
      width: auto;
    }
  }
  @media screen and (max-width: 767px) {
    border-radius: g.vw(10);
    margin-top: 25px;
    width: calc(100% - g.vw(18));
    margin-right: 0;
    margin-left: 0;
    padding: 20px 15px;
    row-gap: 10px;

    &__title {
      font-size: 1.6rem;
    }

    &__link {
      background: url('/svg/icon_arrow.svg') no-repeat right 50%;
      font-family: "Montserrat", sans-serif;
      font-size: 1.2rem;
      font-weight: 700;
      display: block;
      text-align: right;
      // margin-top: 0.75em;
      padding-right: 50px;
    }
  }
  &__left {
    display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      
      @include g.media('md') {
        flex-direction: row;
        gap: g.auto_clamp(20, 721, 50, 1023);
    }
  }
  &__inner {
    @include g.media('md') {
      display: flex;
      align-items: flex-start;
    }
  }
}