.prevNext {
  margin: 25px 0 30px;
  padding: 18px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  font-size: 1.4rem;
  line-height: 1.3;
  border-top: 1px solid var(--color-background);
  border-bottom: 1px solid var(--color-background);

  @media screen and (min-width: 769px) {
    margin: 50px 0 30px;
  }

  > * {
    border-radius: 3px;
    display: flex;
    flex: 1 1 49%;
    align-items: center;
    position: relative;
  }

  &__prev {
    order: 1;

    @media screen and (min-width: 769px) {
      width: 48%;
    }

    .prevNext__title {
      order: 2;
    }
  }

  &__next {
    order: 3;
    justify-content: right;
  }

  &__prev:not(.non)::after,
  &__next:not(.non)::after {
    content: "";
    width: 25px;
    height: 25px;
    // background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24"><path style="fill:%23ff0000;" d="M14 16.94V12.94H5.08L5.05 10.93H14V6.94L19 11.94Z" /></svg>') no-repeat center center;
  }

  &__prev:not(.non)::after {
    transform: scale(-1, 1);
    order: 1;
  }


  &__title {
    color: var(--color-background);
    font-weight: 700;
    padding: 0 .5em;
  }
}