@use "@globals" as g;

.staff-item {
  &__img {
    position: relative;
    margin-bottom: g.vw(40);
  }

  &__name {
    color: var(--color-blue);
    font-family: "Montserrat", sans-serif;
    font-size: g.vw(30);
    font-weight: 700;
    position: absolute;
    bottom: g.vw(-20);
    left: g.vw(20);
    line-height: 1.2;
  }

  &__text {
    padding: 0 g.vw(10) 0 g.vw(24);

    &__name {
      font-size: g.vw(18);
      font-weight: 700;
      margin-bottom: 35px;
    }

    &__info {
      display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 10px 0;

      & dt {
        border: 1px solid var(--color-blue);
        border-radius: 20px;
        color: var(--color-blue);
        font-size: g.vw(13);
        font-weight: 700;
        padding: 0 20px;
        text-align: center;
        text-align-last: justify;
        width: g.vw(100);
      }
      & dd {
        font-size: g.vw(13);
        width: calc(100% - g.vw(110));
      }
    }
  }
}