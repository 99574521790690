@use "@globals" as g;
.shopSection01 {
  &__link {
    border-left: 1px solid #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 80px;
    li {
      border-right: 1px solid #000;
    }
    a {
      color: var(--color-black);
      display: block;
      padding: 0 20px;
    }
  }
}

.shop-section-inner {
  margin-bottom: 40px;
  @include g.media('md') {
    margin-bottom: 90px;
  }
}

.shop-table-wrap {
  margin-top: 30px;
}