@use "@globals" as g;
.l_section__inner {
  padding-right: g.vw(24);
  padding-left: g.vw(24);
  width: 100%;

  @include g.media('md') {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1160px;
  }
}