@use "@globals" as g;
.accidentBox {
  &-wrapper {
    margin-top: g.vw(30);
    margin-bottom: g.vw(60);
    @include g.media('md') {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: g.vw(15);
      margin-bottom: g.vw(30);
    }
    @include g.media('lg') {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 60px;
    }
  }
  &-item {
    background: var(--color-gray);
    border-radius: 20px;
    padding: g.vw(25) g.vw(20);
    & + & {
      margin-top: g.vw(25);
    }
    @include g.media('md') {
      width: 48%;
      margin-top: 0!important;
      margin-bottom: g.vw(15);
      padding: g.vw(20) g.vw(15);
      &:nth-of-type(2) {
        margin-left: 4%;
      }
    }
    @include g.media('lg') {
      width: 370px;
      margin: 0!important;
      padding: 40px;
    }
    &__title {
      color: var(--color-waterBlue);
      font-size: g.vw(15);
      font-weight: 700;
      margin-bottom: g.vw(10);
      @include g.media('md') {
        font-size: g.vw(9);
        margin-bottom: g.vw(8);
      }
      @include g.media('lg') {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
  }
}