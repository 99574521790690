@use "@globals" as g;

.shop-sezyutsu-list-box {
  display: flex;
    justify-content: flex-start;
    gap: 35px;
    flex-wrap: wrap;
    margin-top: 30px;

    & li {
      width: 100%;
      @include g.media('md') {
        width: calc((100% - 35px) / 2);
      }
      @include g.media('lg') {
        width: calc((100% - 70px) / 3 );
      }     
    }
}