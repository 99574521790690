@use "../../../../scss/globals/" as g;
.hero_text {
  color: var(--color-base);
  font-weight: 900;
  line-height: 1.2;
  @media screen and (min-width: 768px) {
    font-size: 110px;
    margin-bottom: 40px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    font-size: g.vw(35);
    margin-bottom: g.vw(10);
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: g.vw(45);
    margin-bottom: g.vw(10);
  }
  @media screen and (max-width: 767px) {
    font-size: g.vw(45);
    margin-bottom: g.vw(10);
  }
}