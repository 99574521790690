@use "../../../../scss/globals/" as g;
.hero_sub_text {
  font-size: 16px;
  font-weight: 700;
  color: var(--color-base);
  line-height: 2;
  //@include g.clamp_fontsize(16, 300, 600, 36);
  &_title {
    font-size: 26px;
    font-weight: 700;
    color: var(--color-base);
    position: relative;
    display: inline-block;
    margin-bottom: 1em;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: var(--color-base);
      position: absolute;
      bottom: -0.5em;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1399px) {
    font-size: g.vw(6);
    &_title {
      font-size: g.vw(8);
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    font-size: g.vw(8);
    &_title {
      font-size: g.vw(10);
    }
  }
  @media screen and (max-width: 767px) {
    font-size: g.vw(15);
    &_title {
      font-size: g.vw(17);
    }
  }
}