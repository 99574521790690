@use "@globals" as g;

.treatment-menu-list-div-box {
    display: block;
    padding-top: g.auto_clamp(57, 375, 100, 1024);
    margin-top: g.auto_clamp(-57, 375, -100, 1024);

    @include g.media('md') {
      padding-top: 100px;
      margin-top: -100px;

    }
}