@use "@globals" as g;
.treatment-check-list {
  background: #F5F5F5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 60px 0;
  padding: g.vw(30) g.vw(25);
  
  @include g.media('md') {
    flex-wrap: wrap;
    flex-direction: row;
    padding: g.vw(15) g.vw(12);
  }
  @include g.media('lg') {
    padding: 55px 125px;
  }

  &__text {
    background: url('/svg/icon_check.svg') no-repeat left center;
    background-size: 18px 16px;
    font-size: g.vw(15);
    font-weight: 700;
    width: 100%;
    margin: 5px 0;
    padding-left: g.vw(26);

    @include g.media('md') {
      width: 50%;
      font-size: g.vw(7);
      padding-left: g.vw(10);
    }
    @include g.media('lg') {
      font-size: 18px;
      padding-left: 25px;
    }
  }
}