@use "@globals" as g;
.table01 {
  width: 100%;
  tr {
    border-bottom: 1px solid #dee3e7;

    @include g.media('lg') {
      border-bottom: 1px solid #dee3e7;
    }
  }
  th,td {
    padding: 25px 10px;

    @include g.media('lg') {
      padding: 25px 0;
    }
  }
  th {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;

    @include g.media('lg') {
      font-size: 20px;
      font-weight: 500;
      padding-left: 60px;
    }
  }
  td {
    font-size: 15px;
    font-weight: 500;

    @include g.media('lg') {
      font-size: 18px;
      font-weight: 500;
    }
  }
}