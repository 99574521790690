@use "@globals" as g;

.shopInfoSection01 {

  &__text {
    margin-bottom: 25px;

    @include g.media('md') {
      margin-bottom: 60px;
    }
  }
  &__inner {
    margin-bottom: 65px;

    @include g.media('md') {
      margin-bottom: 80px;
    }

    & h3 {

      @include g.media('md') {
        margin-bottom: 50px;
      }
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    gap: 35px;
    flex-wrap: wrap;
    margin-top: 30px;

    & li {
      width: 100%;
      @include g.media('md') {
        width: calc((100% - 35px) / 2);
      }
      @include g.media('lg') {
        width: calc((100% - 70px) / 3 );
      }     
      
    }
  }

  &__link {
    border-left: 1px solid #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 80px;
    li {
      border-right: 1px solid #000;
    }
    a {
      color: var(--color-black);
      display: block;
      padding: 0 20px;
    }
  }
}