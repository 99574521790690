@use '@globals' as g;
.h2 {
  color: var(--color-white);
  font-weight: 900;
  line-height: 1.2;
  font-size: g.vw(45);
  font-size: g.auto_clamp(21, 375, 60, 1024);

  @include g.media('lg') {
    font-size: 4rem;
  }
}