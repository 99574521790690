@use "@globals" as g;
.blog-archive {
  @media screen and (min-width: 721px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
  }
  &__row {
    background: var(--color-white);
    box-shadow: 2px 2px 3px #eaeaea;
    border-radius: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    @media screen and (min-width: 721px) {
      width: calc((100% - 60px)/3);
    }
  }
  &__article__eyecatch {
    max-height: 210px;
    overflow: hidden;
    @include g.media('md') {
      max-height: 240px;
    }
    & img {
      width: 100%;
      transition: all .6s ease-out;
    }
  }
  &__link:hover img {
    transform: scale(1.1);
  }
  &__linkBtn {
    text-align: center;
    margin-top: 3em;
  }
  &__wrapper {
    & + & {
      margin-top: 6em;
    }
  }
}

.blog-archive-item {
  color: var(--color-black);
  &__wrapper {
    padding: 20px;
    @include g.media('md') {
      padding: 20px 25px;
    }
  }
  &__date {
    font-size: 1.2rem;
    color: var(--color-waterBlue);
  }
  &__title {
    color: var(--color-black);
    font-size: 1.5rem;
    font-weight: 700;
  }
}