@use "@globals" as g;

.treatment-2way-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin: 60px 0;

  @include g.media('md') {
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 5vw;
  }
  &__title {
    color: var(--color-blue);
    font-size: g.vw(18);
    font-weight: 700;
    margin-bottom: g.vw(20);
    @include g.media('md') {
      font-size: g.vw(11);
      margin-bottom: g.vw(15);
    }
    @include g.media('lg') {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }
  &__text {
    width: 100%;

    @include g.media('md') {
      max-width: 630px;
    }
  }

  &__img {
    margin-bottom: 15px;
    width: 100%;
    max-width: 480px;
    
    @include g.media('md') {
      width: 45%;
      max-width: 500px;
    }
  }
}