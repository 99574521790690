@use "@globals" as g;

.flow_item {
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  margin-top: 60px;

  @include g.media('md') {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5vw;
  }

  &__img {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    max-width: 480px;
    @include g.media('lg') {
      width: 45%;
    }
  }
  &__text {
    width: 100%;
    &__title {
      font-size: g.vw(17);
      font-weight: 700;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      &__no {
        color: #189BEF;
        font-size: g.vw(36);
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        margin-right: 20px;
        line-height: 1;
      }
    }
  }

  @include g.media('md') {
    

    &__img {
      width: 500px;
    }
    &__text {
      max-width: 610px;
      &__title {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        &__no {
          color: #189BEF;
          font-size: 60px;
          font-weight: 700;
          font-family: "Montserrat", sans-serif;
          margin-right: 20px;
          line-height: 1;
        }
      }
    }
  }
  @include g.media('xl') {
    &__img {
      width: 500px;
    }
    &__text {
      width: 610px;
    }
  }
}