.treatment-check-list-div-box {
  &__title {

  }
  &__check-list {

  }
  &__two-way-box {
    display: flex;
      justify-content: center;
  }
}