@use "@globals" as g;
.shopInfoBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 35px; 
  
  @include g.media('md') {
    justify-content: center;
    margin-top: 50px;
    gap: 5vw;
  }

  &__img {
    width: 100%;
    @include g.media('md') {
      width: 45%;
      max-width: 530px;
    }
  }

  &__text {
    padding-top: 1em;
    
    @include g.media('md') {
      width: calc( (100% - 5vw) / 2);
      max-width: 580px;
    }

    &__title {
      font-size: g.vw(20);
      font-weight: 700;
      margin-bottom: 30px;
      @include g.media('md') {
        font-size: 26px;
      }
    }

    &__info {
      margin-bottom: 40px;

      @include g.media('md') {
        margin-bottom: 30px;
      }

      &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 5px 0;

        dt {
          font-size: g.vw(13);
          font-weight: 500;
          margin-right: 30px;
          @include g.media('md') {
            font-size: min(18px,g.vw(6));
          }
        }

        dd {
          font-size: g.vw(13);
          font-weight: 500;
          @include g.media('md') {
            font-size: min(18px,g.vw(6));
          }
        }
      }
    }

    &__link {
      text-align: center;
      
      @include g.media('md') {
        text-align: right;
      }
    }
  }
  & + & {
    margin-top: 45px;

    @include g.media('md') {
      margin-top: 110px;
    }
  }

  &__features {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: .5rem;

    margin-bottom: 50px;
    
    & li {
      font-size: 1.4rem;
      margin-right: 2em;
      margin-bottom: 1em;
    }
    & li[data-icon="各種保険"] {
      background: url('/svg/icon_hoken_black.svg') no-repeat left top 50%;
      padding-left: 2.2em;
    }
    & li[data-icon="カードOK"] {
      background: url('/svg/icon_card_black.svg') no-repeat left top 50%;
      padding-left: 2.2em;
    }
    & li[data-icon="キッズルーム完備"] {
      background: url('/svg/icon_kids_black.svg') no-repeat left top 50%;
      padding-left: 2.1em;
    }
    & li[data-icon="ベビーベッド完備"] {
      background: url('/svg/icon_babybed_black.svg') no-repeat left top 50%;
      padding-left: 2em;
    }
    & li[data-icon="大型駐車場"] {
      background: url('/svg/icon_parking_black.svg') no-repeat left top 50%;
      padding-left: 1.5em;
    }
  }

}