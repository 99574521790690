@use "@globals" as g;

.imageContainer  {
  display: block;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  height: 100%;

  @include g.media('md') {
    display: none;
  }
}

.scrollSlider {
  background: var(--color-white);
  position: relative;
  &__image {
    position: relative;
    padding-bottom: 60px;
  }
  &__bgText {
    color: #eff5e1;
    font-size: 200px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    margin: auto;
    position: absolute;
    bottom: -1em;
    left: 0;
    right: 0;
  }
  @include g.media('md') {
    display: none;
  }
  
}