@use "../../../scss/globals/" as g;

.post_link {
  color: var(--color-white);
  flex-direction: column;
  transition: all .2s ease-out;
  gap: 5px;
  &:hover {
    opacity: 0.6;
  }

  @include g.flex-start;
    flex-direction: column;
    align-items: flex-start;
  
  @include g.media('sm') {
    flex-direction: row;
    column-gap: 5px;
  }
  @include g.media('md') {
    column-gap: 15px;
    flex-direction: row;
  }

  &__label {
    font-weight: 700;
    min-width: 70px;
    text-align: center;
  }

  &__date {
    @include g.flex;
    gap: 15px;
    min-width: 15%;
    > div {
      background: var(--color-green);
      border-radius: 20px;
      font-weight: 700;
      // margin-right: 0.5em;
      @media screen and (min-width: 768px) {
        font-size: 1.2rem;
        padding: 3px 15px;
        gap: 15px;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.2rem;
        padding: 0 15px;
        gap: 5px;
      }
    }
    > span {
      font-weight: 500;
      font-size: 1.4rem;
      // @media screen and (min-width: 768px) {
      //   font-size: 15px;
      // }
      @include g.media('md') {
        font-size: 1.5rem;
      }
    }
  }
  &__title {
    // color: var(--color-primary);
    font-weight: 500;
    font-size: 1.4rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;

    // @media screen and (min-width: 768px) {
    //   font-size: 15px;
    // }
    @include g.media('md') {
      font-size: 1.5rem;
    }
    @media screen and (max-width: 767px) {
    }
  }
  
}